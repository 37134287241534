import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  backgroundColor: string;
};

const ImageWrapper = styled('div')<Props>(({ backgroundColor, theme }) => ({
  backgroundColor,
  margin: '2rem 0 1rem',
  minHeight: '12rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    minHeight: '17rem',
  },
}));

export default ImageWrapper;
