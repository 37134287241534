import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import MediaServerImage from 'components/MediaServerImage';
import styled from '@emotion/styled';

const Image = styled(MediaServerImage)(({ theme }) => ({
  animation: 'fades-in 1s',

  img: {
    [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
      height: '17rem',
      objectFit: 'cover',
      objectPosition: 'left center',
    },

    [mediaQueryBuilder(theme.mediaQueries.max.width['400'])]: {
      objectPosition: '25%',
    },
  },
}));

export default Image;
