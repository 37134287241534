import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const BannerContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  paddingRight: '19rem',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '50%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1280'])]: {
    paddingRight: '3rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    display: 'none',
  },
}));

export default BannerContent;
