import H3 from 'primitives/Typography/Headings/H3';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const Header = styled(H3)(({ theme }) => ({
  color: theme.colors.white.primary,
  fontWeight: theme.fonts.weight.bold,

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    fontSize: theme.fonts.size['20'],
  },
}));

export default Header;
