import BannerContent from './primitives/BannerContent';
import Button from './primitives/Button';
import H2 from 'primitives/Typography/Headings/H2';
import H5 from 'primitives/Typography/Headings/H5';
import Header from './primitives/Header';
import Image from './primitives/Image';
import ImageWrapper from './primitives/ImageWrapper';
import NavLink from 'primitives/Typography/Links/NavLink';
import SubBannerContent from './primitives/SubBannerContent';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import WelcomeSection from 'components/WelcomeSection';
import { useCallback } from 'react';

type Props = {
  assetUrl: string;
  backgroundColor: string;
  btnTitle: string;
  name: string;
  primaryBackgroundSrc: string;
  target: string | null;
  url: string;
  trackItemSelected: (origin: 'for_you' | 'home', url: string) => void;
};

function NewInAppSection({
  assetUrl,
  backgroundColor,
  btnTitle,
  name,
  primaryBackgroundSrc,
  url,
  target,
  trackItemSelected,
}: Props) {
  const translate = useTranslate();

  const onClick = useCallback(() => {
    trackItemSelected('home', url);
    return true;
  }, [trackItemSelected, url]);

  const imageSrc = primaryBackgroundSrc || assetUrl;

  if (!imageSrc) return null;

  const primaryBackground = (
    <Image alt={name} aspectRatio={5.05} background src={imageSrc} />
  );

  return (
    <WelcomeSection bottomDivider>
      <H2 data-test="new-in-app-header">{translate('New on iHeart')}</H2>

      <ImageWrapper backgroundColor={backgroundColor}>
        {primaryBackground}
      </ImageWrapper>

      <BannerContent>
        <Header>{name}</Header>
        <NavLink onClick={onClick} target={target} to={url} underline={false}>
          <Button data-test="new-in-app-banner-button" styleType="light">
            {btnTitle}
          </Button>
        </NavLink>
      </BannerContent>

      <SubBannerContent>
        <H5>{name}</H5>
        <NavLink onClick={onClick} target={target} to={url} underline={false}>
          <Button>{btnTitle}</Button>
        </NavLink>
      </SubBannerContent>
    </WelcomeSection>
  );
}
export default NewInAppSection;
