import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const SubBannerContent = styled('div')(({ theme }) => ({
  display: 'none',

  [mediaQueryBuilder(theme.mediaQueries.max.width['599'])]: {
    display: 'block',
  },
}));

export default SubBannerContent;
